import { createSelector } from 'reselect'

export const stateSelector = state => state.api.patch.one.training_tools

export const data = () =>
  createSelector([stateSelector], trainingToolState => {
    if (trainingToolState.data) {
      return trainingToolState.data
    }
    return null
  })

export const isLoading = () =>
  createSelector(
    [stateSelector],
    traningToolState => traningToolState.isLoading
  )
