/**
 * *******************************
 * BRIQUE - Root - TOKEN_FREE gate
 * *******************************
 * Landing page alias url for wrapping component in TOKEN_FREE gate
 * [
 *    - Home view
 *    - Contact view
 *    - Faq view
 *    - Charter view
 * ]
 *    / \
 *  /  ! \
 * --------
 * Never user fallback here if you set ROOT_VIEW at first index in the base views array
 */
export const ROOT_VIEW = '/'
/* implicite nested routes */
export const HOME_VIEW = ''
export const CHARTER_VIEW = 'charter-mentoring-entreprise'
export const FAQ_VIEW = 'faq-mentoring'
export const CGU_VIEW = 'cgu'
export const CONTACT_VIEW = 'contact-mentoring'
export const DEMO_VIEW = 'peertopeer-platform-demonstration'
export const PEERMENTORING_VIEW = 'peerlearning-mentoring-program'
export const PEERTRAINING_VIEW = 'peerlearning-peertopeer-program'
export const USERAPP_DEMO_VIEW = 'mentoring-platform-user-mentor-mentee'
export const ADMINAPP_DEMO_VIEW = 'mentoring-platform-admin-talent-manager'
export const ABOUT_US_VIEW = 'team-monmentor-mentoring-solution'
export const LEGAL_NOTICE = 'legalnotice-monmentor-mentoring-solution'
export const FORMATION_MENTORING_ENTREPRISE = 'formation-mentoring-entreprise'
export const PEERLEARNING_FORMATION = 'peer-to-peer-strategie-rh'
export const TARIFS = 'conseil-acompagnement-programme-mentoring'
export const DEMO_AFEST_VIEW = 'solution-formation-afest'
export const OAUTH_LOGIN = 'oauth'

export const SIGN_OUT_ROOT_VIEW = 'sign_out'

/**
 * *******************************
 * BRIQUE - Auth - AUTHENTICATED gate
 * *******************************
 * Authentification alias url for wrapping component in AUTHENTICATED gate
 * [
 *    - Sign in view
 *    - Sign up view
 *    - Forgot password view
 *    - Edit password view
 * ]
 * - in @Common
 * [
 *    - Not-found view  fallback
 * ]
 */
export const SIGN_IN_FACEBOOK_CALLBACK = '/omniauth/facebook/callback'
export const SIGN_IN_SAML_CALLBACK = '/omniauth/saml/callback'
export const SIGN_IN_SSO_CALLBACK = '/users/auth/saml/callback'

export const AUTH_VIEW = '/auth'
/* implicite nested routes */
export const SIGN_IN_VIEW = 'sign_in'
export const REDIRECT_TO_SSO = 'redirect_to_sso'
export const ERROR_SSO = '/sso/unauthorized'
export const PROGRAM_SIGN_UP_VIEW = 'program_sign_up'
export const SIGN_IN_FACEBOOK_VIEW = 'facebook'
export const SIGN_IN_SAML_VIEW = 'saml'
export const SIGN_UP_VIEW = 'sign_up/:id?'
export const FORGOT_PASSWORD_VIEW = 'forgot_password'
export const EDIT_PASSWORD_VIEW = 'edit_password'

/**
 * *******************************
 * BRIQUE - UserApp - PROTECTED gate
 * *******************************
 * UserApp alias url for wrapping component in PROTECTED gate
 * [
 *    - Mentoring center view
 *    - Community view
 *    - News view
 *    - Messenger view
 *    - Trainging tools view
 *    - Sign out view
 *    - Profile view
 *    - Webconferences view
 *    - Feedback view
 * ]
 * - in @Common
 * [
 *    - Settings view
 *    - Not-found view  fallback
 * ]
 */
export const USER_APP_VIEW = '/dashboard'
/* implicite nested routes */
export const MENTORING_CENTER_VIEW = 'mentoring_center'
export const MENTORING_CENTER_BIS_VIEW = 'mentoring_center_bis'
export const COMMUNITY_VIEW = 'community'
export const NEWS_VIEW = 'news'
export const MESSENGER_VIEW = 'messenger/:id?'
export const TRAINING_TOOLS_VIEW = 'training_tools'
export const MEETINGS_HISTORY_VIEW = 'meetings_history'
export const PERSONAL_SETTINGS_VIEW = 'personal_settings'
export const AVAILABILITY_SETTINGS_VIEW = 'availability_settings'
/* TODO : to change component no id here */
export const PROFILE_VIEW = 'profile/(presenter|participant)/:id'
export const FEEDBACK_VIEW = 'feedback/:id'
export const MENTORING_RELATIONSHIPS_FEEDBACK_VIEW =
  'feedback_relationships/:id'
export const WEB_CONFERENCES_VIEW = 'webconferences/:id'
export const HELP_VIEW = 'help'

/**
 * *******************************
 * BRIQUE - Admin-app - PROTECTED gate
 * *******************************
 * Admin-app alias url for wrapping component in PROTECTED gate
 * [
 *    - Users view
 *    - Dashboard view
 *    - Programs view
 * ]
 * - in @Common
 * [
 *    - Settings view
 *    - Not-found view  fallback
 * ]
 */

/* implicite nested routes */
export const USERS_VIEW = 'users'
export const DASHBOARD_VIEW = 'dashboard'
export const PROGRAMS_VIEW = 'programs'

/**
 * *******************************
 * BRIQUE - Profiling - PROTECTED gate
 * *******************************
 * Profiling alias url for wrapping component in PROTECTED gate
 */
export const PROFILING_VIEW = '/profiling'
/* implicite nested routes */
export const EDIT_USER_PROFILE_VIEW = ':anchor'

/**
 * *******************************
 * BRIQUE - profile-choice - PROTECTED gate
 * *******************************
 * profile-choice alias url for wrapping component in PROTECTED gate
 */
export const USER_PROFILE_CHOICE_VIEW = '/profile-choice'

/**
 * *******************************
 * BRIQUE - direct-profile-choice - PROTECTED gate
 * *******************************
 * profile-choice alias url for wrapping component in PROTECTED gate
 */
export const EDIT_USER_PROFILE_CHOOSE = '/profile-choice/:anchor'

/**
 * Common & fallback
 * @Common view
 */

export const NOT_FOUND_VIEW = '*'
