import makeLoadable from '@tools/makeLoadable'

import { PROTECTED, AUTHENTICATED, TOKEN_FREE } from '@routes/gates/types'

import * as paths from './paths'
import * as names from './names'

/**
 * ********************************************
 * Absolute Main Router - Base routes
 * ********************************************
 * SubRoutes are relative path
 * ********************************************
 */

/*eslint-disable */

const baseViews = [
  {
    name: names.USER_APP_VIEW,
    path: paths.USER_APP_VIEW,
    gate: PROTECTED,
    exact: false,
    component: makeLoadable(() =>
      import(
        /* webpackChunkName: "UserApp-views" */ '@components/views/UserApp'
      )
    ),
    type: 'app',
    subViews: [
      {
        name: names.MENTORING_CENTER_VIEW,
        path: '',
        exact: true,
        component: makeLoadable(() =>
          import(
            /* webpackChunkName: "mentorig-center-view" */
            '@components/views/UserApp/MentoringCenterView'
          )
        )
      },
      {
        name: names.MENTORING_CENTER_VIEW,
        path: paths.MENTORING_CENTER_VIEW,
        exact: true,
        component: makeLoadable(() =>
          import(
            /* webpackChunkName: "mentoging-center-view" */

            '@components/views/UserApp/MentoringCenterView'
          )
        )
      },
      {
        name: names.COMMUNITY_VIEW,
        path: paths.COMMUNITY_VIEW,
        exact: true,
        component: makeLoadable(() =>
          import(
            /* webpackChunkName: "community-view" */ '@components/views/UserApp/CommunityView/CommunityContainer'
          )
        )
      },
      {
        name: names.HELP_VIEW,
        path: paths.HELP_VIEW,
        exact: true,
        component: makeLoadable(() =>
          import(
            /* webpackChunkName: "community-view" */ '@components/views/UserApp/HelpView'
          )
        )
      },
      {
        name: names.MESSENGER_VIEW,
        path: paths.MESSENGER_VIEW,
        exact: true,
        component: makeLoadable(() =>
          import(
            /* webpackChunkName: "messenger-view" */ '@components/views/UserApp/ChatView/ChatContainer'
          )
        )
      },
      {
        name: names.NEWS_VIEW,
        path: paths.NEWS_VIEW,
        exact: true,
        component: makeLoadable(() =>
          import(
            /* webpackChunkName: "news-view" */ '@components/views/UserApp/ArticlesView/ArticlesContainer'
          )
        )
      },
      {
        name: names.TRAINING_TOOLS_VIEW,
        path: paths.TRAINING_TOOLS_VIEW,
        exact: true,
        component: makeLoadable(() =>
          import(
            /* webpackChunkName: "training-tools-view" */ '@components/views/UserApp/FormationView/FormationContainer'
          )
        )
      },
      {
        name: names.MEETINGS_HISTORY_VIEW,
        path: paths.MEETINGS_HISTORY_VIEW,
        exact: true,
        component: makeLoadable(() =>
          import(
            /* webpackChunkName: "training-tools-view" */ '@components/views/UserApp/MeetingsHistoryView/MeetingsHistoryContainer'
          )
        )
      },
      {
        name: names.PROFILE_VIEW,
        path: paths.PROFILE_VIEW,
        exact: true,
        component: makeLoadable(() =>
          import(
            /* webpackChunkName: "profile-view" */ '@components/views/UserApp/ProfileView/ProfileContainer'
          )
        )
      },
      {
        name: names.FEEDBACK_VIEW,
        path: paths.FEEDBACK_VIEW,
        exact: true,
        component: makeLoadable(() =>
          import(
            /* webpackChunkName: "feedback-view" */ '@components/views/UserApp/FeedbackView/FeedbackContainer'
          )
        )
      },
      {
        name: names.MENTORING_RELATIONSHIPS_FEEDBACK_VIEW,
        path: paths.MENTORING_RELATIONSHIPS_FEEDBACK_VIEW,
        exact: true,
        component: makeLoadable(() =>
          import(
            /* webpackChunkName: "feedback-relationships-view" */ '@components/views/UserApp/MentoringRelationshipsFeedbackView'
          )
        )
      },
      {
        name: names.MENTORING_RELATIONSHIPS_FEEDBACK_VIEW,
        path: paths.MENTORING_RELATIONSHIPS_FEEDBACK_VIEW,
        exact: true,
        component: makeLoadable(() =>
          import(
            /* webpackChunkName: "feedback-relationships-view" */ '@components/views/UserApp/MentoringRelationshipsFeedbackView'
          )
        )
      },
      {
        name: names.WEB_CONFERENCES_VIEW,
        path: paths.WEB_CONFERENCES_VIEW,
        exact: true,
        component: makeLoadable(() =>
          import(
            /* webpackChunkName: "web-conferences-view" */ '@components/views/UserApp/WebconferenceView/WebconferenceContainer'
          )
        )
      },
      {
        name: names.PERSONAL_SETTINGS_VIEW,
        path: paths.PERSONAL_SETTINGS_VIEW,
        exact: true,
        component: makeLoadable(() =>
          import(
            /* webpackChunkName: "PersonalSettingsView" */ '@components/views/UserApp/PersonalSettingsView'
          )
        )
      },
      {
        name: names.AVAILABILITY_SETTINGS_VIEW,
        path: paths.AVAILABILITY_SETTINGS_VIEW,
        exact: true,
        component: makeLoadable(() =>
          import(
            /* webpackChunkName: "PersonalSettingsView" */ '@components/views/UserApp/AvailabilitySettingsView'
          )
        )
      },
      {
        name: names.NOT_FOUND_VIEW,
        path: paths.NOT_FOUND_VIEW,
        exact: false,
        status: 404,
        component: makeLoadable(() =>
          import(
            /* webpackChunkName: "NotFoundView" */ '@components/views/NotFoundView'
          )
        )
      }
    ]
  },
  {
    name: names.USER_PROFILE_CHOICE_VIEW,
    path: paths.USER_PROFILE_CHOICE_VIEW,
    gate: PROTECTED,
    exact: true,
    component: makeLoadable(() =>
      import(
        /* webpackChunkName: "profile-choice-view" */ '@components/views/ProfileChoiceView'
      )
    ),
    subViews: [
      {
        name: names.EDIT_USER_PROFILE_CHOOSE,
        path: paths.EDIT_USER_PROFILE_CHOOSE,
        exact: true,
        component: makeLoadable(() =>
          import(
            /* webpackChunkName: "edit-profile-choice-view" */ '@components/views/ProfileChoiceView/DirectChooseProfile'
          )
        )
      }
    ]
  },
  {
    name: names.EDIT_USER_PROFILE_CHOOSE,
    path: paths.EDIT_USER_PROFILE_CHOOSE,
    gate: PROTECTED,
    exact: true,
    component: makeLoadable(() =>
      import(
        /* webpackChunkName: "edit-profile-choice-view" */ '@components/views/ProfileChoiceView/DirectChooseProfile'
      )
    )
  },
  {
    name: names.PROFILING_VIEW,
    path: paths.PROFILING_VIEW,
    exact: false,
    gate: PROTECTED,
    component: makeLoadable(() =>
      import(
        /* webpackChunkName: "profiling-views" */ '@components/views/ProfilingView'
      )
    ),
    subViews: [
      {
        name: names.EDIT_USER_PROFILE_VIEW,
        path: paths.EDIT_USER_PROFILE_VIEW,
        exact: true,
        component: makeLoadable(() =>
          import(
            /* webpackChunkName: "edit-profile-view" */ '@components/views/ProfilingView/EditUserProfileView'
          )
        )
      },
      {
        name: names.NOT_FOUND_VIEW,
        path: paths.NOT_FOUND_VIEW,
        exact: false,
        component: makeLoadable(() =>
          import(
            /* webpackChunkName: "NotFoundView" */ '@components/views/NotFoundView'
          )
        )
      }
    ]
  },
  {
    name: names.SIGN_IN_FACEBOOK_CALLBACK,
    path: paths.SIGN_IN_FACEBOOK_CALLBACK,
    gate: AUTHENTICATED,
    type: 'auth',
    exact: false,
    component: makeLoadable(() =>
      import(
        /* webpackChunkName: "auth-facebook" */ '@components/views/auth/SsoOauth/OauthCallback'
      )
    )
  },
  {
    name: names.SIGN_IN_SSO_CALLBACK,
    path: paths.SIGN_IN_SSO_CALLBACK,
    gate: AUTHENTICATED,
    type: 'auth',
    exact: false,
    component: makeLoadable(() =>
      import(
        /* webpackChunkName: "auth-sso" */ '@components/views/auth/SsoOauth/OauthCallback'
      )
    )
  },
  {
    name: names.SIGN_IN_SAML_CALLBACK,
    path: paths.SIGN_IN_SAML_CALLBACK,
    gate: AUTHENTICATED,
    type: 'auth',
    exact: false,
    component: makeLoadable(() =>
      import(
        /* webpackChunkName: "auth-saml" */ '@components/views/auth/SsoOauth/OauthCallback'
      )
    )
  },
  {
    name: names.AUTH_VIEW,
    path: paths.AUTH_VIEW,
    gate: AUTHENTICATED,
    exact: false,
    component: makeLoadable(() =>
      import(/* webpackChunkName: "auth-views" */ '@components/views/auth')
    ),
    type: 'auth',
    subViews: [
      {
        name: names.ERROR_SSO,
        path: paths.ERROR_SSO,
        exact: true,
        component: makeLoadable(() =>
          import(
            /* webpackChunkName: "error_sso-view" */ '@components/views/auth/sso-oauth/SsoError'
          )
        )
      },
      {
        name: names.SIGN_UP_VIEW,
        path: paths.SIGN_UP_VIEW,
        exact: true,
        component: makeLoadable(() =>
          import(
            /* webpackChunkName: "SignUpView" */ '@components/views/auth/SignUpView'
          )
        )
      },
      {
        name: names.SIGN_IN_VIEW,
        path: paths.SIGN_IN_VIEW,
        exact: true,
        component: makeLoadable(() =>
          import(
            /* webpackChunkName: "SignInView" */ '@components/views/auth/SignInView'
          )
        )
      },
      {
        name: names.SIGN_IN_FACEBOOK_VIEW,
        path: paths.SIGN_IN_FACEBOOK_VIEW,
        exact: false,
        component: makeLoadable(() =>
          import(
            /* webpackChunkName: "sign-in-facebook" */ '@components/views/auth/SsoOauth/OauthComponent.jsx'
          )
        )
      },
      {
        name: names.SIGN_IN_SAML_VIEW,
        path: paths.SIGN_IN_SAML_VIEW,
        exact: false,
        component: makeLoadable(() =>
          import(
            /* webpackChunkName: "sign-in-saml" */ '@components/views/auth/SsoOauth/OauthComponent.jsx'
          )
        )
      },
      {
        name: names.FORGOT_PASSWORD_VIEW,
        path: paths.FORGOT_PASSWORD_VIEW,
        exact: true,
        component: makeLoadable(() =>
          import(
            /* webpackChunkName: "ForgotPasswordView" */ '@components/views/auth/ForgotPasswordView'
          )
        )
      },
      {
        name: names.EDIT_PASSWORD_VIEW,
        path: paths.EDIT_PASSWORD_VIEW,
        exact: true,
        component: makeLoadable(() =>
          import(
            /* webpackChunkName: "EditPasswordView" */ '@components/views/auth/EditPasswordView'
          )
        )
      },
      {
        name: names.PROGRAM_SIGN_UP_VIEW,
        path: paths.PROGRAM_SIGN_UP_VIEW,
        exact: true,
        component: makeLoadable(() =>
          import(
            /* webpackChunkName: "EditPasswordView" */ '@components/views/auth/ProgramAuthView'
          )
        )
      },
      {
        name: names.PROGRAM_SIGN_UP_VIEW,
        path: paths.PROGRAM_SIGN_UP_VIEW,
        exact: true,
        component: makeLoadable(() =>
          import(
            /* webpackChunkName: "edit-password-view" */ '@components/views/auth/ProgramAuthView'
          )
        )
      },
      {
        name: names.NOT_FOUND_VIEW,
        path: paths.NOT_FOUND_VIEW,
        exact: false,
        component: makeLoadable(() =>
          import(
            /* webpackChunkName: "NotFoundView" */ '@components/views/NotFoundView'
          )
        )
      }
    ]
  },
  {
    name: names.ROOT_VIEW,
    path: paths.ROOT_VIEW,
    gate: TOKEN_FREE,
    exact: false,
    component: makeLoadable(() =>
      import(/* webpackChunkName: "root-views" */ '@components/views/root')
    ),
    subViews: [
      {
        name: names.HOME_VIEW,
        path: paths.HOME_VIEW,
        exact: true,
        component: makeLoadable(() =>
          import(
            /* webpackChunkName: "home-view" */ '@components/views/root/HomePage/HomePageContainer'
          )
        )
      },
      {
        name: names.CONTACT_VIEW,
        path: paths.CONTACT_VIEW,
        exact: true,
        component: makeLoadable(() =>
          import(
            /* webpackChunkName: "contact-view" */ '@components/views/root/Contact/ContactContainer'
          )
        )
      },
      {
        name: names.FAQ_VIEW,
        path: paths.FAQ_VIEW,
        exact: true,
        component: makeLoadable(() =>
          import(
            /* webpackChunkName: "faq-view" */ '@components/views/root/Faq/FaqContainer'
          )
        )
      },
      {
        name: names.CGU_VIEW,
        path: paths.CGU_VIEW,
        exact: true,
        component: makeLoadable(() =>
          import(
            /* webpackChunkName: "faq-view" */ '@components/views/root/Cgu/CguCompany'
          )
        )
      },
      {
        name: names.CHARTER_VIEW,
        path: paths.CHARTER_VIEW,
        exact: true,
        component: makeLoadable(() =>
          import(
            /* webpackChunkName: "charter-view" */ '@components/views/root/Charter/CharterContainer'
          )
        )
      },
      {
        name: names.OAUTH_LOGIN,
        path: paths.OAUTH_LOGIN,
        gate: AUTHENTICATED,
        type: 'auth',
        exact: false,
        component: makeLoadable(() =>
          import(
            /* webpackChunkName: "oauth-login" */ '@components/views/auth/SsoOauth/OAuthLogin'
          )
        )
      },
      {
        name: names.SIGN_OUT_ROOT_VIEW,
        path: paths.SIGN_OUT_ROOT_VIEW,
        exact: true,
        component: makeLoadable(() =>
          import(
            /* webpackChunkName: "SignOutView" */ '@components/views/auth/SignOutView'
          )
        )
      },
      {
        name: names.REDIRECT_TO_SSO,
        path: paths.REDIRECT_TO_SSO,
        exact: true,
        component: makeLoadable(() =>
          import(
            /* webpackChunkName: "redirect_to_sso-view" */ '@components/views/auth/SsoOauth/RedirectToSSO'
          )
        )
      },
      {
        name: names.DEMO_AFEST_VIEW,
        path: paths.DEMO_AFEST_VIEW,
        exact: true,
        component: makeLoadable(() =>
          import(
            /* webpackChunkName: "afest_demo-view" */ '@components/views/root/CustomPage/afest/AfestDemo'
          )
        )
      },
      {
        name: names.NOT_FOUND_VIEW,
        path: paths.NOT_FOUND_VIEW,
        gate: TOKEN_FREE,
        exact: false,
        component: makeLoadable(() =>
          import(
            /* webpackChunkName: "NotFoundView" */ '@components/views/NotFoundView'
          )
        )
      }
    ]
  },
  {
    name: names.NOT_FOUND_VIEW,
    path: paths.NOT_FOUND_VIEW,
    gate: TOKEN_FREE,
    exact: false,
    component: makeLoadable(() =>
      import(
        /* webpackChunkName: "NotFoundView" */ '@components/views/NotFoundView'
      )
    )
  }
]

/* eslint-enable */

export default baseViews
