const Api = {
  validations: process.env.NODE_ENV === 'development',
  baseURL: process.env.API_BASE_URL,
  axios: {
    baseURL: `https://${process.env.API_BASE_URL}`,
    timeout: 20000,
    withCredentials: false,
    responseType: 'json',
    responseEncoding: 'utf8',
    maxRedirects: 2,
    headers: {
      'content-type': 'application/json'
    }
  },
  actionCable: {
    baseURL: `wss://${process.env.API_BASE_URL}`
  },
  initialRequestState: {
    data: null,
    isLoading: null,
    lastUpdated: null,
    lastUpdatedBefore: null,
    lastUpdatedSuccess: null,
    lastUpdatedFailed: null,
    lastUpdatedDelete: null,
    lastUpdatedUpdate: null,
    lastUpdatedReset: null,
    requestSpeed: null,
    requestCountBefore: null,
    requestCountSuccess: null,
    requestCountFailed: null,
    actionCountUpdate: null,
    actionCountDelete: null,
    actionCountReset: null,
    storedFrom: null,
    errors: null
  },
  changeHeadersOnEachRequest: true,
  forceRefreshHeadersBeforeRequest: true,
  onUnauthorized: enzyme =>
    enzyme.resetExcept({
      type: 'get',
      subType: 'all',
      model: 'companies'
    }),
  authHeaders: ['access-token', 'token-type', 'client', 'expiry', 'uid'],
  rootKey: 'api',
  typesRequests: ['get', 'post', 'patch', 'put', 'delete'],
  immutableStoresSubTypes: ['all', 'paginated'],
  prefix: {
    request: {
      before: 'api->request:',
      success: 'api->middleware:',
      failed: 'api->middleware:',
      afterSuccess: {
        update: 'api->middleware:after:',
        delete: 'api->middleware:after:'
      }
    },
    reset: {
      specific: 'api->reset:',
      all: 'api->reset:'
    },
    criticalValidation: {
      valid: 'api->validators:',
      invalid: 'api->validators:'
    }
  },
  separators: {
    actionName: '_'
  },
  suffix: {
    request: {
      before: ':loading',
      success: ':success',
      failed: ':failed',
      afterSuccess: {
        update: ':updated',
        delete: ':deleted'
      }
    },
    reset: {
      specific: ':specific',
      all: 'all'
    },
    criticalValidation: {
      valid: ':valid',
      invalid: ':invalid'
    }
  },
  default: {
    request: {
      notifications: {}
    }
  },
  routes: {
    get: {
      one: {
        current_user: {
          pathAttributes: ['id'],
          path: ({ id }) => `/api/v1/users/${id}`
        },
        mentoring_request: {
          pathAttributes: ['id'],
          path: ({ id }) => `/api/v1/mentoring_requests/${id}`
        },
        validate_token: {
          path: () => '/auth/validate_token'
        },
        users: {
          pathAttributes: ['id'],
          path: ({ id }) => `/api/v1/users/${id}`
        },
        presenters: {
          pathAttributes: ['id'],
          path: ({ id }) => `/api/v1/presenters/${id}`
        },
        participants: {
          pathAttributes: ['id'],
          path: ({ id }) => `/api/v1/participants/${id}`
        },
        conversations: {
          pathAttributes: ['id'],
          path: ({ id }) => `/api/v1/conversations/${id}`
        },
        profile: {
          pathAttributes: ['profileType', 'id'],
          path: ({ id, profileType }) => `/api/v1/${profileType}/${id}`
        },
        availabilities: {
          pathAttributes: ['id'],
          path: ({ id }) => `/api/v1/users/${id}/availabilities`
        },
        webconferences: {
          pathAttributes: ['id'],
          path: ({ id }) => `/api/v1/meetings/${id}/webconference`
        },
        meetings: {
          pathAttributes: ['id'],
          path: ({ id }) => `/api/v1/meetings/${id}`
        },
        current_profile: {
          path: () => `/api/v1/current_profile`
        },
        mentoring_relationships: {
          pathAttributes: ['id'],
          path: ({ id }) => `/api/v1/mentoring_relationships/${id}`
        }
      },
      all: {
        notifications: {
          path: () => `/api/v1/notifications`
        },
        online: {
          path: () => `/api/v1/online`
        },
        presenters: {
          path: () => '/api/v1/presenters'
        },
        participants: {
          path: () => '/api/v1/participants'
        },
        presenter_steps: {
          path: () => '/api/v1/presenter_steps'
        },
        participant_steps: {
          path: () => '/api/v1/participant_steps'
        },
        profile_questions: {
          path: () => '/api/v1/profile_questions'
        },
        training_tools: {
          path: () => '/api/v1/training_tools'
        },
        articles: {
          path: () => '/api/v1/articles'
        },
        mentoring_requests: {
          path: () => '/api/v1/mentoring_requests'
        },
        meeting_reasons: {
          path: () => '/api/v1/meeting_reasons'
        },
        meetings: {
          path: () => '/api/v1/meetings'
        },
        conversations: {
          path: () => '/api/v1/conversations'
        },
        feedback_questions: {
          path: () => '/api/v1/feedback_questions?subcategory=meeting'
        },
        feedback_questions_mentoring_relationships: {
          path: () =>
            '/api/v1/feedback_questions?subcategory=mentoring_relationship'
        },
        feedbacks: {
          path: () => '/api/v1/feedbacks'
        },
        meetings_feedbacks: {
          pathAttributes: ['id'],
          path: ({ id }) => `/api/v1/meetings/${id}/feedbacks`
        },
        companies: {
          path: () => '/api/v1/companies'
        },
        availabilities: {
          path: () => '/api/v1/availabilities'
        },
        tags: {
          path: () => '/api/v1/tags'
        },
        faqs: {
          pathAttributes: ['locale'],
          path: ({ locale }) => `/api/v1/faqs?locale=${locale}`
        },
        charters: {
          pathAttributes: ['locale'],
          path: ({ locale }) => `/api/v1/charters?locale=${locale}`
        },
        job_category: {
          path: () => '/api/v1/profile_questions/job_category'
        },
        business_sector: {
          path: () => '/api/v1/profile_questions/business_sector'
        },
        tags_admin: {
          path: () => '/api/v1/tags?admin=true'
        },
        filter_steps: {
          path: () => '/api/v1/profile_questions?is_filter=true'
        },
        presenter_recommendations: {
          pathAttributes: ['id'],
          path: ({ id }) => `/api/v1/presenters/${id}/recommendations`
        },
        mentoring_relationships: {
          path: () => '/api/v1/mentoring_relationships'
        },
        meetings_history: {
          path: () => '/api/v1/meetings_history'
        },
        mentoring_relationships_feedbacks: {
          pathAttributes: ['id'],
          path: ({ id }) => `/api/v1/mentoring_relationships/${id}/feedbacks`
        },
        mentoring_relationships_meeting_request: {
          pathAttributes: ['id'],
          path: ({ id }) =>
            `/api/v1/mentoring_relationships/${id}/mentoring_request`
        },
        programs: {
          path: () => `/api/v1/programs`
        },
        programs_presenters: {
          pathAttributes: ['id'],
          path: id => `/api/v1/programs/${id}/presenters`
        },
        program_enrollments: {
          path: () => `/api/v1/program_enrollments`
        },
        user_questions: {
          pathAttributes: ['locale'],
          path: locale => `/api/v1/user_questions?locale=${locale}`
        },
        filtered_presenters: {
          pathAttributes: ['query'],
          path: ({ query }) => `/api/v1/presenters?${query}`
        }
      },
      paginated: {
        presenters: {
          path: () => `api/v1/presenters`
        }
      }
    },
    post: {
      one: {
        registrations: {
          path: () => '/auth'
        },
        sessions: {
          path: () => '/auth/sign_in'
        },
        forgot_password: {
          path: () => '/auth/password',
          dataPointer: response => response.data
        },
        articles: {
          path: () => '/api/v1/articles'
        },
        conversations: {
          path: () => '/api/v1/conversations'
        },
        mentoring_requests: {
          path: () => '/api/v1/mentoring_requests'
        },
        articles_likes: {
          pathAttributes: ['id'],
          path: ({ id }) => `/api/v1/articles/${id}/like`
        },
        conversations_comments: {
          pathAttributes: ['id'],
          path: ({ id }) => `/api/v1/conversations/${id}/comments`
        },
        meetings: {
          path: () => '/api/v1/meetings'
        },
        meetings_appointments: {
          pathAttributes: ['id'],
          path: ({ id }) => `/api/v1/meetings/${id}/appointments`
        },
        meetings_feedbacks: {
          pathAttributes: ['id'],
          path: ({ id }) => `/api/v1/meetings/${id}/feedbacks`
        },
        mentoring_relationships_feedbacks: {
          pathAttributes: ['id'],
          path: ({ id }) => `/api/v1/mentoring_relationships/${id}/feedbacks`
        },
        webconferences: {
          pathAttributes: ['id'],
          path: ({ id }) => `/api/v1/meetings/${id}/webconference`
        },
        profile: {
          pathAttributes: ['profileType'],
          path: ({ profileType }) => `/api/v1/${profileType}`
        },
        contact_form: {
          path: () => `/api/v1/contact_form`
        },
        articles_comments: {
          pathAttributes: ['id'],
          path: ({ id }) => `/api/v1/articles/${id}/comments`
        },
        presenter_recommendations: {
          pathAttributes: ['id'],
          path: ({ id }) => `/api/v1/presenters/${id}/recommendations`
        }
      }
    },
    patch: {
      one: {
        notifications: {
          pathAttributes: ['id'],
          path: ({ id }) => `/api/v1/notifications/${id}`
        },
        registrations: {
          path: () => '/auth'
        },
        registrations_preferences: {
          path: () => '/auth'
        },
        mentoring_relationships: {
          pathAttributes: ['id'],
          path: ({ id }) => `/api/v1/mentoring_relationships/${id}`
        },
        mentoring_requests: {
          pathAttributes: ['id'],
          path: ({ id }) => `/api/v1/mentoring_requests/${id}`
        },
        meetings_appointments: {
          pathAttributes: ['idMeeting', 'idAppointment'],
          path: ({ idMeeting, idAppointment }) =>
            `/api/v1/meetings/${idMeeting}/appointments/${idAppointment}`
        },
        meetings_feedbacks: {
          pathAttributes: ['idMeeting', 'idFeedback'],
          path: ({ idMeeting, idFeedback }) =>
            `/api/v1/meetings/${idMeeting}/feedbacks/${idFeedback}`
        },
        meetings: {
          pathAttributes: ['id'],
          path: ({ id }) => `/api/v1/meetings/${id}`
        },
        articles: {
          pathAttributes: ['id'],
          path: ({ id }) => `/api/v1/articles/${id}`
        },
        profile: {
          pathAttributes: ['profileType', 'id'],
          path: ({ profileType, id }) => `/api/v1/${profileType}/${id}`
        },
        meeting: {
          pathAttributes: ['id'],
          path: ({ id }) => `/api/v1/meetings/${id}`
        },
        availabilities: {
          pathAttributes: ['id'],
          path: ({ id }) => `/api/v1/availabilities/${id}`
        },

        presenter_recommendations: {
          pathAttributes: ['userId', 'recommendationId'],
          path: ({ userId, recommendationId }) =>
            `/api/v1/presenters/${userId}/recommendations/${recommendationId}`
        },
        mentoring_relationships_feedbacks: {
          pathAttributes: ['mentoringRelationshipId', 'feedbackId'],
          path: ({ mentoringRelationshipId, feedbackId }) =>
            `/api/v1/mentoring_relationships/${mentoringRelationshipId}/feedbacks/${feedbackId}`
        },
        users: {
          pathAttributes: ['id'],
          path: ({ id }) => `/api/v1/users/${id}`
        },
        program_enrollments: {
          pathAttributes: ['id'],
          path: ({ id }) => `/api/v1/program_enrollments/${id}`
        },
        training_tools: {
          pathAttributes: ['id'],
          path: ({ id }) => `/api/v1/training_tools/${id}/increment_click_count`
        }
      },
      all: {
        notifications_as_read: {
          path: () => '/api/v1/notifications/mark-all-as-read'
        },
        conversation_comments_seen: {
          pathAttributes: ['conversationId'],
          path: ({ conversationId }) =>
            `/api/v1/conversations/${conversationId}/comments/mark-as-seen`
        }
      }
    },
    put: {
      one: {
        edit_password: {
          path: () => '/auth/password',
          dataPointer: response => response.data.data
        },
        comments: {
          pathAttributes: ['id', 'idComment'],
          path: ({ id, idComment }) =>
            `/api/v1/articles/${id}/comments/${idComment}`
        }
      }
    },
    delete: {
      one: {
        meetings: {
          pathAttributes: ['id'],
          path: ({ id }) => `/api/v1/meetings/${id}`
        },
        meetings_feedbacks: {
          pathAttributes: ['meetingId', 'feedbackId'],
          path: ({ meetingId, feedbackId }) =>
            `/api/v1/meetings/${meetingId}/feedbacks/${feedbackId}`
        },
        registrations: {
          path: () => '/auth'
        },
        presenter_recommendations: {
          pathAttributes: ['userId', 'recommendationId'],
          path: ({ userId, recommendationId }) =>
            `/api/v1/presenters/${userId}/recommendations/${recommendationId}`
        },
        sessions: {
          path: () => '/auth/sign_out'
        },
        articles: {
          pathAttributes: ['id'],
          path: ({ id }) => `/api/v1/articles/${id}`,
          dispatchAfterSuccess: {
            type: 'get',
            subType: 'all',
            model: 'articles'
          }
        },
        comments: {
          pathAttributes: ['id', 'idComment'],
          path: ({ id, idComment }) =>
            `/api/v1/articles/${id}/comments/${idComment}`
        }
      }
    }
  }
}

export default Api
