/* eslint-disable no-nested-ternary */
import { createSelector } from 'reselect'

export const stateSelector = state => state.api.get.all.meetings_history

export const data = () =>
  createSelector(
    [stateSelector],
    mentoringRequestsState => mentoringRequestsState.data
  )

export const isLoading = () =>
  createSelector(
    [stateSelector],
    mentoringRequestState => mentoringRequestState.isLoading
  )
